import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { FaPhone, FaWhatsapp } from "react-icons/fa"
import { Link } from "react-router-dom";
import { bookingURL } from "../csconfig";
import {
  BookOnline,
  Email,
  WhatsappOutlined,
  Google,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { whatsappText } from "../whatsappText";
import TripAdvisor_img from "../assets/TripAdvisor_img.jpg";

function Footer() {
  return (
    <div className="bg-[#6dc1b2] min-h-screen text-black flex items-center">
      <div className="w-full grid xl:grid-template-col xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 gap-20 xl:p-20 py-5">
        <div className="m-auto">
          <div className="mb-5 text-left w-full font-bold">
            <p className="text-lg text-left">The Bunker House</p>
          </div>
          <div className="flex flex-row justify-center text-white60 transition duration-700 ease-in-out">
            <a
              href="https://instagram.com/thebunkerhousein?igshid=YmMyMTA2M2Y="
              rel="noreferrer"
              target="_blank"
            >
              <InstagramIcon
                className="hover:text-white transition duration-700 ease-in-out cursor-pointer"
                style={{ fontSize: 20, marginRight: "7.5px" }}
              />
            </a>

            <a
              href="mailto:harjihospitalities@gmail.com"
              rel="noreferrer"
              target="_blank"
            >
              <Email
                className="hover:text-white transition duration-700 ease-in-out cursor-pointer"
                style={{ fontSize: 20, marginRight: "7.5px" }}
              />
            </a>
            <a
              href="https://www.tripadvisor.in/Hotel_Review-g12324032-d23549091-Reviews-The_Bunker_House_cafe_stay-Chopta_Rudraprayag_District_Uttarakhand.html"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={TripAdvisor_img}
                alt=""
                className="hover:text-white transition duration-700 ease-in-out cursor-pointer w-6 mr-1 rounded-full"
              />
            </a>
            <a>
              <WhatsappOutlined
                className="hover:text-white transition duration-700 ease-in-out cursor-pointer p- 0"
                onClick={() =>
                  whatsappText(
                    "Hi, I am reaching out to you via thebunkerhouse.in website."
                  )
                }
                style={{ fontSize: 20, marginRight: "7.5px" }}
              />
            </a>
            <a href="https://g.co/kgs/tD6Bss" rel="noreferrer" target="_blank">
              <Google
                className="hover:text-white transition duration-700 ease-in-out cursor-pointer"
                style={{ fontSize: 20, marginRight: "7.5px" }}
              />
            </a>
          </div>
        </div>
        <div className="m-auto text-center">
          <div className="mb-5 text-left w-full font-bold">
            <p className="text-lg text-center">Quick Links</p>
          </div>
          <Link to="/chopta" onClick={() => window.scrollTo(0, 0)}>
            <div className="hover:text-white pb-2 text-white60">Home</div>
          </Link>
          <Link to="/chopta/stay" onClick={() => window.scrollTo(0, 0)}>
            <div className="hover:text-white pb-2 text-white60">Stay</div>
          </Link>
          <Link to="/chopta/cafe" onClick={() => window.scrollTo(0, 0)}>
            <div className="hover:text-white pb-2 text-white60">Cafe</div>
          </Link>
          <Link to="/chopta/experience" onClick={() => window.scrollTo(0, 0)}>
            <div className="hover:text-white pb-2 text-white60">Experience</div>
          </Link>
          <Link to="/chopta" onClick={() => window.open(bookingURL, "_blank")}>
            <div className="hover:text-white pb-2 text-white60">
              Reservation
            </div>
          </Link>
          <Link
            to="/chopta"
            onClick={() =>
              window.open(
                "https://fantasy-crawdad-8fb.notion.site/5a5cbdcdf81b473498111bb32239a56e",
                "_blank"
              )
            }
          >
            <div className="hover:text-white pb-2 text-white60">About Us</div>
          </Link>
          <Link
            to="/chopta"
            onClick={() =>
              window.open(
                "https://fantasy-crawdad-8fb.notion.site/5a5cbdcdf81b473498111bb32239a56e",
                "_blank"
              )
            }
          >
            <div className="hover:text-white pb-2 text-white60">
              Terms and Conditions
            </div>
          </Link>
          <Link
            to="/chopta"
            onClick={() =>
              window.open(
                "https://fantasy-crawdad-8fb.notion.site/5a5cbdcdf81b473498111bb32239a56e",
                "_blank"
              )
            }
          >
            <div className="hover:text-white pb-2 text-white60">
              Privacy Policy
            </div>
          </Link>
          <Link
            to="/chopta"
            onClick={() =>
              window.open(
                "https://fantasy-crawdad-8fb.notion.site/5a5cbdcdf81b473498111bb32239a56e",
                "_blank"
              )
            }
          >
            <div className="hover:text-white pb-2 text-white60">
              Refunds/Cancellation
            </div>
          </Link>
        </div>
        <div className="m-auto">
          <div className="mb-5 text-left w-full font-bold">
            <p className="text-lg text-center">Map</p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13750.924825634353!2d79.165607!3d30.5003566!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xff531d85d1d4621d!2sThe%20Bunker%20House%20cafe%20%26%20stay!5e0!3m2!1sen!2sin!4v1653292184396!5m2!1sen!2sin"
            frameBorder="0"
            style={{
              border: 0,
              margin: "0 auto",
              height: "180px",
              width: "180px",
            }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
        <div className="m-auto">
          <div className="mb-5 text-left  w-full font-bold">
            <p className="text-lg text-center">Get in Touch</p>
          </div>
          <form name="contact" method="post">
            <input type="hidden" name="form-name" value="contact" />
            <div className="w-full bg-white p-3 rounded-md">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="appearance-none mt-2 block w-full border-2 rounded-md py-1 px-2 leading-tight focus:outline-none text-sm  placeholder:text-black  focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4]"
              />

              <input
                type="text"
                name="email"
                placeholder="Email"
                className="appearance-none mt-2 block w-full border-2 rounded-md py-1 px-2 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
              />

              <textarea
                type="text"
                name="message"
                placeholder="Message"
                className="h-auto appearance-none mt-2 block w-full border-2 rounded-md py-1 px-2 leading-tight focus:outline-none text-sm placeholder:text-black focus:bg-[#C2E3F4] focus:text-black focus:border-[#C2E3F4] "
              ></textarea>

              <button
                type="submit"
                className="appearance-none rounded-md block w-full mt-3 bg-[#6dc1b2] py-2 text-sm font-semibold text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <a
          href="tel:+919045951195"
          className="whatsapp-button inline-block fixed bottom-[25px] left-[20px] w-[50px] h-[50px] bg-[#6dc1b2] p-[12px] rounded-full text-white z-10 text-[25px] overflow-hidden"
        >
          <span className="sr-only">WhatsApp</span>
          <FaPhone className="transform rotate-90" />
        </a>

        <a
          href={`https://wa.me/919045951195`}
          target="_blank"
          className="whatsapp-button inline-block fixed bottom-[20px] right-[20px] w-[50px] h-[50px] bg-[#4DC251] p-[12px] rounded-full text-white z-10 text-[25px] overflow-hidden"
        >
          <span className="sr-only">WhatsApp</span>
          <FaWhatsapp />
        </a>
      </div>


    </div>
  );
}

export default Footer;
